 const stations = {
 "type": "FeatureCollection",
 "name": "stations",
 "features": [
 { "type": "Feature", "properties": { "marker": "53+50", "photoid": "3A93150" }, "geometry": { "type": "Point", "coordinates": [ -73.97053426312354, 40.674680019837957 ] } },
 { "type": "Feature", "properties": { "marker": "52+95", "photoid": "3A93151" }, "geometry": { "type": "Point", "coordinates": [ -73.970608461169547, 40.674871766175585 ] } },
 { "type": "Feature", "properties": { "marker": "52+53", "photoid": "3A93152" }, "geometry": { "type": "Point", "coordinates": [ -73.970748733900692, 40.675061603916447 ] } },
 { "type": "Feature", "properties": { "marker": "52+00", "photoid": "3A93153" }, "geometry": { "type": "Point", "coordinates": [ -73.970882844342555, 40.67524671911832 ] } },
 { "type": "Feature", "properties": { "marker": "51+10", "photoid": "3A93230" }, "geometry": { "type": "Point", "coordinates": [ -73.970968675025333, 40.675356567456625 ] } },
 { "type": "Feature", "properties": { "marker": "50+65", "photoid": "3A93229" }, "geometry": { "type": "Point", "coordinates": [ -73.971024301812662, 40.675449908193073 ] } },
 { "type": "Feature", "properties": { "marker": "50+15", "photoid": "3A93228" }, "geometry": { "type": "Point", "coordinates": [ -73.971119037481259, 40.675568263429895 ] } },
 { "type": "Feature", "properties": { "marker": "49+65", "photoid": "3A93227" }, "geometry": { "type": "Point", "coordinates": [ -73.971231377830179, 40.675698736975313 ] } },
 { "type": "Feature", "properties": { "marker": "49+15", "photoid": "3A93226" }, "geometry": { "type": "Point", "coordinates": [ -73.971331164786889, 40.675818520695316 ] } },
 { "type": "Feature", "properties": { "marker": "48+65", "photoid": "3A93225" }, "geometry": { "type": "Point", "coordinates": [ -73.971414565273363, 40.675950524154779 ] } },
 { "type": "Feature", "properties": { "marker": "48+15", "photoid": "3A93224" }, "geometry": { "type": "Point", "coordinates": [ -73.971536039884285, 40.676147153814242 ] } },
 { "type": "Feature", "properties": { "marker": "47+65", "photoid": "3A93223" }, "geometry": { "type": "Point", "coordinates": [ -73.971595870662796, 40.676244780912199 ] } },
 { "type": "Feature", "properties": { "marker": "46+65", "photoid": "3A93220" }, "geometry": { "type": "Point", "coordinates": [ -73.97175329390403, 40.676420428889841 ] } },
 { "type": "Feature", "properties": { "marker": "46+15", "photoid": "3A93219" }, "geometry": { "type": "Point", "coordinates": [ -73.971855081660763, 40.676553618730196 ] } },
 { "type": "Feature", "properties": { "marker": "45+65", "photoid": "3A93218" }, "geometry": { "type": "Point", "coordinates": [ -73.97197967181387, 40.676753233575127 ] } },
 { "type": "Feature", "properties": { "marker": "45+15", "photoid": "3A93217" }, "geometry": { "type": "Point", "coordinates": [ -73.972124511087131, 40.676942412440063 ] } },
 { "type": "Feature", "properties": { "marker": "44+50", "photoid": "3A93216" }, "geometry": { "type": "Point", "coordinates": [ -73.972210341769923, 40.677052257984471 ] } },
 { "type": "Feature", "properties": { "marker": "44+12", "photoid": "3A93215" }, "geometry": { "type": "Point", "coordinates": [ -73.972237163858296, 40.677097009821054 ] } },
 { "type": "Feature", "properties": { "marker": "43+65", "photoid": "3A93214" }, "geometry": { "type": "Point", "coordinates": [ -73.972314947914569, 40.677208889281069 ] } },
 { "type": "Feature", "properties": { "marker": "43+15", "photoid": "3A93212" }, "geometry": { "type": "Point", "coordinates": [ -73.972382003148056, 40.677304495226096 ] } },
 { "type": "Feature", "properties": { "marker": "42+00", "photoid": "3A93159" }, "geometry": { "type": "Point", "coordinates": [ -73.972510803167722, 40.677481611315066 ] } },
 { "type": "Feature", "properties": { "marker": "41+50", "photoid": "3A93160" }, "geometry": { "type": "Point", "coordinates": [ -73.972628766361595, 40.677636063684581 ] } },
 { "type": "Feature", "properties": { "marker": "41+00", "photoid": "3A93161" }, "geometry": { "type": "Point", "coordinates": [ -73.972708534291456, 40.677744653801398 ] } },
 { "type": "Feature", "properties": { "marker": "40+50", "photoid": "3A93162" }, "geometry": { "type": "Point", "coordinates": [ -73.972830262113987, 40.677889133804214 ] } },
 { "type": "Feature", "properties": { "marker": "40+00", "photoid": "3A93172" }, "geometry": { "type": "Point", "coordinates": [ -73.972963344552014, 40.678063957572782 ] } },
 { "type": "Feature", "properties": { "marker": "39+50", "photoid": "3A93173" }, "geometry": { "type": "Point", "coordinates": [ -73.973080597297567, 40.678242467473559 ] } },
 { "type": "Feature", "properties": { "marker": "38+50", "photoid": "3A93191" }, "geometry": { "type": "Point", "coordinates": [ -73.973217547247231, 40.678429925004444 ] } },
 { "type": "Feature", "properties": { "marker": "37+50", "photoid": "3A93195" }, "geometry": { "type": "Point", "coordinates": [ -73.973375784420767, 40.678652548465685 ] } },
 { "type": "Feature", "properties": { "marker": "37+00", "photoid": "3A93196" }, "geometry": { "type": "Point", "coordinates": [ -73.973483384195688, 40.678787869399436 ] } },
 { "type": "Feature", "properties": { "marker": "36+50", "photoid": "3A93197" }, "geometry": { "type": "Point", "coordinates": [ -73.973576418499732, 40.678913461929533 ] } },
 { "type": "Feature", "properties": { "marker": "36+15", "photoid": "3A93249" }, "geometry": { "type": "Point", "coordinates": [ -73.973661131275207, 40.679034231991665 ] } },
 { "type": "Feature", "properties": { "marker": "35+65", "photoid": "3A93250" }, "geometry": { "type": "Point", "coordinates": [ -73.973742242857128, 40.679156611975195 ] } },
 { "type": "Feature", "properties": { "marker": "35+15", "photoid": "3A93251" }, "geometry": { "type": "Point", "coordinates": [ -73.973838817230458, 40.679273927924747 ] } },
 { "type": "Feature", "properties": { "marker": "34+65", "photoid": "3A93252" }, "geometry": { "type": "Point", "coordinates": [ -73.973927217293976, 40.679386093933566 ] } },
 { "type": "Feature", "properties": { "marker": "34+15", "photoid": "3A93253" }, "geometry": { "type": "Point", "coordinates": [ -73.974019382106576, 40.679509993137358 ] } },
 { "type": "Feature", "properties": { "marker": "33+50", "photoid": "3A93238" }, "geometry": { "type": "Point", "coordinates": [ -73.974104275341787, 40.679623314778418 ] } },
 { "type": "Feature", "properties": { "marker": "33+00", "photoid": "3A93174" }, "geometry": { "type": "Point", "coordinates": [ -73.974212314482912, 40.679754753553148 ] } },
 { "type": "Feature", "properties": { "marker": "32+50", "photoid": "3A93175" }, "geometry": { "type": "Point", "coordinates": [ -73.974308907213668, 40.679900051409739 ] } },
 { "type": "Feature", "properties": { "marker": "32+00", "photoid": "3A93176" }, "geometry": { "type": "Point", "coordinates": [ -73.97439250581462, 40.68001825405377 ] } },
 { "type": "Feature", "properties": { "marker": "31+50", "photoid": "3A93177" }, "geometry": { "type": "Point", "coordinates": [ -73.974516123194661, 40.680140701357956 ] } },
 { "type": "Feature", "properties": { "marker": "31+00", "photoid": "3A93178" }, "geometry": { "type": "Point", "coordinates": [ -73.974607640954062, 40.680251139689368 ] } },
 { "type": "Feature", "properties": { "marker": "29+80", "photoid": "3A93243" }, "geometry": { "type": "Point", "coordinates": [ -73.97467385101524, 40.680372976568627 ] } },
 { "type": "Feature", "properties": { "marker": "29+15", "photoid": "3A93266" }, "geometry": { "type": "Point", "coordinates": [ -73.974817051776427, 40.680553592053393 ] } },
 { "type": "Feature", "properties": { "marker": "28+65", "photoid": "3A93267" }, "geometry": { "type": "Point", "coordinates": [ -73.974942842132108, 40.680747805399619 ] } },
 { "type": "Feature", "properties": { "marker": "28+15", "photoid": "3A93268" }, "geometry": { "type": "Point", "coordinates": [ -73.975046570050836, 40.680865798085904 ] } },
 { "type": "Feature", "properties": { "marker": "27+65", "photoid": "3A93270" }, "geometry": { "type": "Point", "coordinates": [ -73.975114307813143, 40.680987205297328 ] } },
 { "type": "Feature", "properties": { "marker": "27+15", "photoid": "3A93271" }, "geometry": { "type": "Point", "coordinates": [ -73.975198405501686, 40.681111851871613 ] } },
 { "type": "Feature", "properties": { "marker": "26+65", "photoid": "3A93272" }, "geometry": { "type": "Point", "coordinates": [ -73.975279683638249, 40.681225992691253 ] } },
 { "type": "Feature", "properties": { "marker": "26+15", "photoid": "3A93273" }, "geometry": { "type": "Point", "coordinates": [ -73.975390453650505, 40.681354774095063 ] } },
 { "type": "Feature", "properties": { "marker": "25+65", "photoid": "3A93274" }, "geometry": { "type": "Point", "coordinates": [ -73.975467246115429, 40.681476010348291 ] } },
 { "type": "Feature", "properties": { "marker": "25+15", "photoid": "3A93275" }, "geometry": { "type": "Point", "coordinates": [ -73.975536216044333, 40.681578102408743 ] } },
 { "type": "Feature", "properties": { "marker": "24+65", "photoid": "3A93277" }, "geometry": { "type": "Point", "coordinates": [ -73.975613034107525, 40.681699523448692 ] } },
 { "type": "Feature", "properties": { "marker": "24+15", "photoid": "3A93278" }, "geometry": { "type": "Point", "coordinates": [ -73.97570950220512, 40.681845836554736 ] } },
 { "type": "Feature", "properties": { "marker": "23+65", "photoid": "3A93280" }, "geometry": { "type": "Point", "coordinates": [ -73.975825263922275, 40.682016527441398 ] } },
 { "type": "Feature", "properties": { "marker": "22+65", "photoid": "3A93281" }, "geometry": { "type": "Point", "coordinates": [ -73.976018200117466, 40.682299390795805 ] } },
 { "type": "Feature", "properties": { "marker": "22+15", "photoid": "3A93282" }, "geometry": { "type": "Point", "coordinates": [ -73.976114668215061, 40.682445710800764 ] } },
 { "type": "Feature", "properties": { "marker": "21+65", "photoid": "3A93283" }, "geometry": { "type": "Point", "coordinates": [ -73.9762239987257, 40.682596895840184 ] } },
 { "type": "Feature", "properties": { "marker": "20+65", "photoid": "3A93285" }, "geometry": { "type": "Point", "coordinates": [ -73.976422428356926, 40.682898553196807 ] } },
 { "type": "Feature", "properties": { "marker": "20+15", "photoid": "3A93286" }, "geometry": { "type": "Point", "coordinates": [ -73.976540488682261, 40.683070369794898 ] } },
 { "type": "Feature", "properties": { "marker": "19+65", "photoid": "3A93287" }, "geometry": { "type": "Point", "coordinates": [ -73.976676304197682, 40.683237732905518 ] } },
 { "type": "Feature", "properties": { "marker": "19+15", "photoid": "3A93288" }, "geometry": { "type": "Point", "coordinates": [ -73.976775102816376, 40.683353768823146 ] } },
 { "type": "Feature", "properties": { "marker": "18+65", "photoid": "3A93846" }, "geometry": { "type": "Point", "coordinates": [ -73.976855270101453, 40.683448242805945 ] } },
 { "type": "Feature", "properties": { "marker": "18+15", "photoid": "3A93847" }, "geometry": { "type": "Point", "coordinates": [ -73.976958807160372, 40.68356438779287 ] } },
 { "type": "Feature", "properties": { "marker": "17+15", "photoid": "3A93848" }, "geometry": { "type": "Point", "coordinates": [ -73.977059111047254, 40.683690556313948 ] } }
 ]
 }



export default stations
